<template>
  <div class="rfq-view-page">
    <p class="page-breadcrumbs-manual"><span @click="$router.push('/')">Home </span><span @click="$router.push('/claims')">// View Rental Claims</span> <span class="active">// View Rental Claim</span></p>

<!--    <b-card v-if="!isOpenPdf">-->
<!--      <b-form-input placeholder="Search"></b-form-input>-->
<!--    </b-card>-->

    <div v-if="!isOpenPdf" class="" style="padding-left: 22px">
      <p><b>Ref: {{claim.refNbr}}</b></p>
      <p>{{formatDate(claim.vehicle.arriveDate)}}</p>
    </div>

    <div v-if="!isOpenPdf" class="d-flex" style="justify-content: space-between">
      <div class="col-lg-9">
        <b-card>
          <b-form-checkbox v-model="claim.partsRequest" :disabled="true"  style="margin-bottom: 10px; margin-left: 15px">
            Rental Car requested
          </b-form-checkbox>

          <div class="d-flex">
            <div class="col-lg-4">
              <div role="group">
                <label for="rego">Rego:</label>
                <b-form-input
                    id="rego"
                    placeholder="Rego"
                    trim
                    readonly
                    v-model="claim.vehicle.rego"
                ></b-form-input>
              </div>

              <div role="group">
                <label for="make">Make:</label>
                <b-form-input
                    id="make"
                    placeholder="Make"
                    trim
                    readonly
                    v-model="claim.vehicle.make"
                ></b-form-input>
              </div>

              <div role="group">
                <label for="model">Model:</label>
                <b-form-input
                    id="model"
                    placeholder="Model"
                    v-model="claim.vehicle.model"
                    trim
                    readonly
                ></b-form-input>
              </div>

              <div role="group">
                <label for="refNumber">Reference Nbr:</label>
                <b-form-input
                    id="refNumber"
                    placeholder="Reference Nbr"
                    v-model="claim.vehicle.refNbr"
                    trim
                    readonly
                ></b-form-input>
              </div>
            </div>
            <div class="col-lg-4">
              <div role="group">
                <label for="arriveDate">Arrive Date:</label>
                <input
                    id="arriveDate"
                    placeholder="Arrive Date"
                    readonly
                    :value="formatDate2(claim.vehicle.arriveDate)"
                    class="form-control"
                />
              </div>

              <div role="group">
                <label for="startDate">Start Date:</label>
                <input
                    id="startDate"
                    placeholder="Start Date"
                    readonly
                    :value="formatDate2(claim.vehicle.startDate)"
                    class="form-control"
                />
              </div>

              <div role="group">
                <label for="complDate">Compl Date:</label>
                <input
                    id="complDate"
                    readonly
                    :value="formatDate2(claim.vehicle.completeDate)"
                    placeholder="Compl Date"
                    class="form-control"
                />
              </div>

              <div role="group">
                <label for="pickupDate">Pickup Date:</label>
                <input
                    id="pickupDate"
                    placeholder="Pickup Date"
                    readonly
                    :value="formatDate2(claim.vehicle.pickupDate)"
                    class="form-control"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div role="group">
                <label for="accidentDesc">Accident Description:</label>
                <b-form-textarea
                    id="accidentDesc"
                    placeholder="Accident Description"
                    rows="3"
                    readonly
                    v-model="claim.vehicle.accidentDesc"
                    no-resize
                ></b-form-textarea>
              </div>
              <div role="group">
                <label for="notes">Notes:</label>
                <b-form-textarea
                    id="notes"
                    placeholder="Notes"
                    rows="3"
                    no-resize
                    readonly
                    v-model="claim.vehicle.notes"
                ></b-form-textarea>
              </div>
            </div>
          </div>
        </b-card>

        <div class="">
          <div class="d-flex">
            <div class="d-flex dashboard-tab-title" @click="activeTab = 'activity'">
              <div class="dashboard-tab-text" :class="{'active': activeTab == 'activity'}">Shared Activity</div>
              <div class="dashboard-tab-line" v-if="activeTab == 'activity'"></div>
            </div>
            <div class="d-flex dashboard-tab-title" @click="activeTab = 'files'">
              <div class="dashboard-tab-text" :class="{'active': activeTab == 'files'}">Files</div>
              <div class="dashboard-tab-line" v-if="activeTab == 'files'"></div>
            </div>
            <div class="d-flex dashboard-tab-title" @click="activeTab = 'images'">
              <div class="dashboard-tab-text" :class="{'active': activeTab == 'images'}">Images</div>
              <div class="dashboard-tab-line" v-if="activeTab == 'images'"></div>
            </div>
            <div class="d-flex dashboard-tab-title" @click="activeTab = 'request'">
              <div class="dashboard-tab-text" :class="{'active': activeTab == 'request'}">Parts Request</div>
              <div class="dashboard-tab-line" v-if="activeTab == 'request'"></div>
            </div>
          </div>
          <div class="main-tab-line"></div>
        </div>
        <b-card title="Shared Activity">

          <div class="d-flex">
            <div style="padding: 0px; display: flex; justify-content: center; width: 40px;">
              <div style="width: 3px; height: 100%; background-color: #7367f0;"></div>
            </div>

            <div style="padding-left: 0px; width: 100%">
<!--              <b-card style="border: 3px solid #f8f8f8">-->
<!--                <div class="d-flex">-->
<!--                  <div class="col-lg-8">-->
<!--                    <b-card-text><b>Vehicle Booking Added</b></b-card-text>-->
<!--                    <b-card-text>Added by Name</b-card-text>-->
<!--                  </div>-->
<!--                  <div class="col-lg-4" style="text-align: end">-->
<!--                    Monday 12 Aug 2024-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-card>-->
<!--              <b-card style="border: 3px solid #f8f8f8">-->
<!--                <div class="d-flex">-->
<!--                  <div class="col-lg-8">-->
<!--                    <b-card-text><b>Vehicle Booking Added</b></b-card-text>-->
<!--                    <b-card-text>Added by Name</b-card-text>-->
<!--                  </div>-->
<!--                  <div class="col-lg-4" style="text-align: end">-->
<!--                    Monday 12 Aug 2024-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-card>-->
            </div>
          </div>
        </b-card>
      </div>

      <div class="col-lg-3">
        <b-card>
          <b-card-text>Customer Name</b-card-text>
          <b-card-text>{{claim.customer.name}}</b-card-text>
          <b-card-text>Customer ID: {{claim.customer.customerNbr}}</b-card-text>
          <b-card-text>
            <b-avatar :text="claim.customer.leads ? claim.customer.leads : '0'" style="margin-right: 10px" variant="primary"></b-avatar>
            <span class="mr-auto">Leads</span>
          </b-card-text>
          <b-card-text><b>Contact Info</b></b-card-text>
          <b-card-text>Phone: {{claim.customer.phone}}</b-card-text>
        </b-card>

        <b-card>
          <b-card-text><b>Contact Name</b></b-card-text>
          <b-card-text>{{claim.vehicleOwner.name}}</b-card-text>
          <b-card-text><b>Contact Address</b></b-card-text>
          <b-card-text> {{claim.vehicleOwner.address}}</b-card-text>
          <b-card-text><b>Contact Details</b></b-card-text>
          <b-card-text>Email: {{claim.vehicleOwner.email}}</b-card-text>
          <b-card-text>Phone: {{claim.vehicleOwner.phone}}</b-card-text>
        </b-card>

        <b-card v-if="claim.taxInvoice && claim.taxInvoice.id">
          <b-card-text   @click="openPdf">
            <b class="text-primary" style="margin-right: 10px; cursor: pointer">{{claim.taxInvoice.name}}</b>
            <feather-icon
                icon="DownloadIcon"
                size="21"
                style="color: #c05209; cursor: pointer"
            />
          </b-card-text>
        </b-card>
      </div>
    </div>

    <PdfViewer
        v-if="isOpenPdf"
        :documentPath="claim.taxInvoice.src"
        @closePdf="isOpenPdf = false"
        :documentHeight="documentHeight"
        :documentWidth="documentWidth"></PdfViewer>
  </div>
</template>

<script>
import {BCard, BCardText, BLink, BFormCheckbox, BFormInput, BRow, BAvatar, BFormTextarea} from 'bootstrap-vue'
import PdfViewer from "@/views/PdfViewer";
import Axios from "axios";
import * as dayjs from "dayjs";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormCheckbox,
    BFormInput,
    BRow,
    BAvatar,
    BFormTextarea,
    PdfViewer,
  },
  data() {
    return {
      interval: null,
      activeTab: 'activity',
      claim: {
        "id": null,
        "refNbr": null,
        "customer": {"name": null, "customerNbr": null, "id": null, "phone": null, "leads": null},
        "taxInvoice": {
          "id": null,
          "src": null,
          "name": null
        },
        "sharedActivity": [],
        "files": [],
        "images": [],
        "partsRequest": null,
        "vehicle": {
          "rego": null,
          "make": null,
          "model": null,
          "refNbr": null,
          "arriveDate": null,
          "startDate": null,
          "completeDate": null,
          "pickupDate": null,
          "accidentDesc": null,
          "notes": null
        },
        "vehicleOwner": {
          "id": null,
          "name":null,
          "phone": null,
          "email": null,
          "address": null
        },
        "lastUpdated": null
      },
      isOpenPdf: false,
      documentHeight: 0,
      documentWidth: 0,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.interval)
  },
  methods: {
    handleResize() {
      this.documentHeight =  window.innerHeight - 50

      this.documentWidth =  window.innerWidth - document.getElementsByClassName('main-menu')?.[0]?.clientWidth
    },
    openPdf() {
      this.isOpenPdf = true
    },
    formatDate(v) {
      if (v) {
        return  dayjs(v).format('MMM DD YYYY')
      }
      return ''
    },
    formatDate2(v) {
      if (v) {
        return  dayjs(v).format('DD/MM/YYYY')
      }
      return ''
    },
    loadClaim() {
      Axios.get(`/api/v1/claim/${this.$route.params.id}`).then((r) => {
        if (r?.data?._status) {
          this.claim = r.data.claim
        }
      })
    },
    checkUpdate() {
      let timestamp = this.$store.getters['lastTimestamp'] ? this.$store.getters['lastTimestamp'] - 5 : 0
      Axios.get(`/api/v1/update/claim/${this.$route.params.id}/${timestamp}`).then((r) => {
        if (r?.data?._status && r?.data?.update?.isNeedUpdate) {
          this.loadClaim()
        }
      })
    }
  },
  created() {
    this.loadClaim();

    this.interval = setInterval(() => {
     this.checkUpdate();
    }, 5000)
  }
}
</script>

<style>
.rfq-view-page .page-breadcrumbs-manual {
  margin-left: 22px;
}

.rfq-view-page .form-control:read-only {
  background-color: white !important;
}

.rfq-view-page .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: black !important;
}


.rfq-view-page .custom-control-input[disabled] ~ .custom-control-label::before, [dir] .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fff !important;
  border: #d8d6de solid 1px !important;
}

.rfq-view-page .custom-control-input:checked[disabled] ~ .custom-control-label::before, [dir] .custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
}

.dashboard-tab-line {
  height: 5px;
  background-color: #7367f0;
  border-radius: 5px 5px 0px 0px;
}

.dashboard-tab-title .dashboard-tab-text.active {
  color: #7367f0;
}

.dashboard-tab-title .dashboard-tab-text {
  height: 40px;
  padding: 5px 15px;
  align-items: start;
  display: flex;
}

.main-tab-line {
  height: 7px;
  width: 100%;
  background-color: #e3e4e7;
}

.dashboard-tab-title {
  flex-direction: column;
  cursor: pointer;
}
</style>

